import U from './lib-utils';
import template from '!!raw-loader!./templates/lib-confirm/confirm.html';
require('./assets/lib-confirm/style.scss');
import mustache from '@/lib/vendor/mustache/mustache';
import {callback} from './lib-callback-manager';


var icons = {};
var files = require.context('!file-loader!./templates/lib-confirm/icons', false, /\.svg$/);
files.keys().forEach(function (key) {
    var m = /^.\/(.*)\.svg$/.exec(key);
    if (m) {
        icons[m[1].toLowerCase()] = require('!!raw-loader!./templates/lib-confirm/icons/' + m[1] + '.svg').default;
    }
});

function confirm() {
    return (confirm.is(this) ? this.init : confirm.F).apply(this, Array.prototype.slice.call(arguments));
}

var AP = U.fixup_constructor(confirm).prototype;
AP.options = null;
AP.handle = null;
AP.instance_id = null;
AP.buttons = null;
AP.init = function (options) {
    this.instance_id = ['confirm', U.get_uuid()].join('-');
    this.options = this.get_default_options();
    var ops = U.safe_object(options);
    for (var k in ops) {
        if (Object.prototype.hasOwnProperty.call(ops, k)) {
            if (Object.prototype.hasOwnProperty.call(this.options, k)) {
                this.options[k] = ops[k];
            }
        }
    }
    this.buttons = U.safe_array(this.options.buttons);
    this.set_message(this.options.text);
    this.set_callback(this.options.callback)
            .set_command_processor(this.options.command_processor);
    if (U.any_bool(this.options.show, false)) {
        this.show();
    }

    return this;
};

AP.clearButtons = AP.clear_buttons = function () {
    this.buttons = [];
    return this;
};

AP.addButton = AP.add_button = function (title, code, css) {
    this.buttons.push({
        title: U.NEString(title, 'button'),
        code: U.NEString(code, U.get_uuid()),
        css: U.NEString(css, 'default')
    });
    return this;
};

AP.show = function () {
    this.init_handle();
    return this;
};

AP.set_title = function (title) {
    this.options.title = U.NEString(title, this.options.title);
    return this;
};

AP.set_text = function (text) {
    this.options.text = U.NEString(text, this.options.text).replace(/\n/g, '<br/>');
    return this;
};
AP.set_message = AP.set_text;

AP.set_style = function (st_name) {
    this.options.style = U.NEString(st_name, 'default');
    return this;
};

AP.set_icon = function (x) {
    if (!x) {
        this.options.icon = false;
    }
    var xv = U.NEString(x, null);
    this.options.icon = xv ? xv : false;
    return this;
};
AP.set_command_processor = function (ca) {
    if (callback.is(ca)) {
        this.options.command_processor = ca;
    } else if (U.is_callable(ca)) {
        this.options.command_processor = callback(ca);
    } else {
        this.options.command_processor = null;
    }
    return this;
};

AP.set_callback = function (ca) {
    if (callback.is(ca)) {
        this.options.callback = ca;
    } else if (U.is_callable(ca)) {
        this.options.callback = callback(ca);
    } else {
        this.options.callback = null;
    }
    return this;
};

AP.get_default_options = function () {
    return {
        title: 'title',
        text: 'text',
        style: 'default',
        callback: null,
        command_processor: null,
        show: false,
        icon: false,
        buttons: [
            {css: "default", code: 'btnCANCEL', title: "Cancel"},
            {css: "default", code: 'btnOK', title: "Ok"}
        ]
    };
};

function render_dom(obj) {
    var div = document.createElement('div');
    div.innerHTML = mustache.render(template, obj);
    return div.children[0];
}

AP.init_handle = function () {
    this.handle = render_dom(this);
    this.init_handlers();
    U.getBody().appendChild(this.handle);
};


AP.init_handlers = function () {
    this.handle.addEventListener('click', _click.bindTo(this));
};

AP._on_command_close = function () {
    this.hide();
    return true;
};

AP.hide = function () {
    this.handle.removeEventListener('click', _click.bindTo(this));
    if (window.requestAnimationFrame) {
        var start = performance.now();
        var self = this;
        var animation = function (time) {
            var timeFraction = (time - start) / 300;
            if (timeFraction > 1) {
                timeFraction = 1;
            }
            var opacity = Math.max(1.0 - (timeFraction * 2.0), 0);
            var scale = 0.8 + timeFraction * 3;
            self.handle.style.opacity = opacity.toFixed(6);
            self.handle.style.transform = ['scale(', scale.toFixed(6), ')'].join('');
            if (timeFraction < 1) {
                requestAnimationFrame(animation);
                return;
            }
            self.handle.remove();
        };
        requestAnimationFrame(animation);
    } else {
        this.handle.remove();
    }
};


AP.render_window_class = function () {
    var c = [];
    c.push(['lib-confirm-window-style-', U.NEString(this.options.style, 'none')].join(''));

    if (this.options.icon && U.NEString(this.options.icon, null)) {
        c.push('lib-confirm-window-icon-enabled');
    }
    return c.join(' ');
};

AP.render_title_text = function () {
    return U.NEString(this.options.title, '-title-');
};

AP.render_confirm_content = function () {
    return U.NEString(this.options.text, '-text-');
};
AP.render_instance_id = function () {
    return this.instance_id;
};

AP.render_icon = function () {
    if (null === U.NEString(this.options.icon, null)) {
        return '';
    } else if (/^preset:/i.test(this.options.icon)) {
        return this.render_predefined_icon();
    } else if (/^#/i.test(this.options.icon)) {
        return ['<svg><use xlink:href="', this.options.icon, '"></svg>'].join('');
    } else if (/<svg/i.test(this.options.icon)) {
        return this.options.icon;
    } else if (U.NEString(this.options.icon, null)) {
        return ['<div class="lib-confirm-window-icon-image"><img src="', this.options.icon, '"/></div>'].join('');
    }
    return '';
};

AP.render_predefined_icon = function () {
    var m = /^preset:(\S{1,})$/i.exec(this.options.icon);
    if (m) {
        var c = U.NEString(m[1].toLowerCase(), null);
        if (c) {
            if (Object.prototype.hasOwnProperty.call(icons, c)) {
                return icons[c];
            }
        }
    }
    return '';
};

AP.onCommand_tap = function (n, e) {
    var code = U.NEString(n.dataset.code, null);
    if (null !== code) {
        if (callback.is(this.options.callback)) {
            this.options.callback.run(code);
            e.stopPropagation();
            e.preventDefault();
            this.hide();
        }
    }
};

function _click(e) {
    var command_node = U.upToDatasetKey(e.target, this.handle, 'libConfirmCommand');
    if (command_node) {
        var command = U.NEString(command_node.dataset.libConfirmCommand, null);
        if (command) {
            var fn = ['onCommand_', command.toLowerCase()].join('');
            if (U.is_callable(this[fn])) {
                return this[fn](command_node, e);
            }
            if (this.options.command_processor) {
                if (false !== this.options.command_processor.run(command, command_node, e, this)) {
                    return this;
                }
            }
        }
    }
}

export default confirm;