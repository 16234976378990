<template>
    <div class="local-preloader-component" v-show="visible" role="alert" :aria-labelledby="'label'+instance_id+'text'" tabindex="-1" ref="hnd">
        <div class="local-preloader-content" role='none'>
            <div class="local-preloader-element" v-html="svgImage" role="none" aria-hidden="true" ></div>
            <div style="display:none" aria-hidden="true">
                <div :id="'label'+instance_id+'text'" v-text="TT('common.loading')"></div>
            </div>
        </div>
    </div>
</template>
<style   lang="scss">
    .local-preloader-component {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        user-select: none;
        * {
            user-select: none;
        }
    }

    .local-preloader-content {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        background: rgba(100,100,100,.3);
    }

    .local-preloader-element {
        width: 2em;
        height: 2em;
        line-height: 0;
        pointer-events: none;
        svg {
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }

</style>
<script>
    import svgImage from '!!raw-loader!@/svg/common/preloader.svg';
    import U from './../lib-utils';
    export default {
        name: 'local-preloader',
        data: function () {
            return {
                instance_id: ['a', U.get_uuid()].join(''),
                visible: false,
                counter: 0,
                svgImage: svgImage
            };
        },
        props: {
            shown: {
                type: Boolean,
                'default': false
            }
        },
        methods: {
            show: function () {
                this.$.data.counter += 1;
                if (this.$.data.counter) {
                    this.$.data.visible = true;
                }
            },
            hide: function () {
                this.$.data.counter -= 1;
                if (this.$.data.counter < 0) {
                    this.$.data.counter = 0;
                }
                if (this.$.data.counter === 0) {
                    this.$.data.visible = false;
                }
            },
            focus: function () {
                try {
                    this.$refs.hnd.focus();
                } catch (e) {
                    // do nothing
                }
            }
        },
        mounted: function () {
            if (this.$props.shown) {
                this.$.data.visible = true;
            }
        }
    }
</script>

